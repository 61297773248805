import { useEffect, useState } from "react"
import { api } from "../../../lib/axios"
import usePage from "../../../context/usePage"
import LoadingSpinner from "../../utilities/LoadingSpinner"
import TrashBinList from "./TrashBinList"

const TrashBinPage = () => {
  const [trashBinData, setTrashBinData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken
  const { selectedPage } = usePage()

  useEffect(() => {
    const fetchShedulerData = async () => {
      setIsLoading(true)
      try {
        const response = await api.get("/rewritten-posts/", {
          params: {
            relationship__child_page_id: selectedPage.id,
            status: "rejected",
          },
          headers: {
            "Session-Token": token,
          },
        })
        const responseData = response.data
        setTrashBinData(responseData)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchShedulerData()
  }, [selectedPage, token])

  const save = async (change) => {
    const { postid, action } = change
    try {
      const response = await api.patch(
        `/rewritten-posts/${postid}/`,
        {
          status: action,
        },
        {
          headers: {
            "Session-Token": token,
          },
        },
      )
      if (response.status === 200) {
        setTrashBinData((pre) => pre.filter((item) => item.id !== postid))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const postActionHandler = (postid, action) => {
    save({
      postid,
      action,
    })
  }

  return (
    <section className="container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <TrashBinList posts={trashBinData} onPostAction={postActionHandler} />
      )}
    </section>
  )
}

export default TrashBinPage
