import FeedHeader from "./FeedHeader"
import FeedList from "./FeedList"
import { acb } from "../../../lib/axios"
import { useEffect, useState } from "react"
import usePage from "../../../context/usePage"
import { useNavigate } from "react-router-dom"
import LoadingSpinner from "../../utilities/LoadingSpinner"

const FeedPage = () => {
  const [feedData, setFeedData] = useState([])
  const [articleShares, setArticleShares] = useState("")
  const [sortPostedtime, setPostedtime] = useState("pd")
  const [isLoading, setIsLoading] = useState(true)
  const { selectedPage } = usePage()
  const navigate = useNavigate()

  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken

  useEffect(() => {
    const fetchFeedData = async () => {
      setIsLoading(true)
      try {
        const response = await acb.get("feed/posts", {
          params: {
            pages: [selectedPage.id],
            shares: articleShares,
            sorting: sortPostedtime,
          },
          paramsSerializer: { indexes: null },
          headers: {
            "Session-Token": token,
          },
        })
        const responseData = await response.data.data.posts
        setFeedData(responseData)
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/login?expired=true", { replace: true })
        }
      } finally {
        setIsLoading(false)
      }
    }
    fetchFeedData()
  }, [
    token,
    selectedPage.name,
    articleShares,
    sortPostedtime,
    navigate,
    selectedPage.id,
  ])

  // sn = sharedOnce, so = shardOnce, sm = sharedMany
  const changeArticleSharesHandler = (childState) => {
    if (
      childState === "sn" ||
      childState === "so" ||
      childState === "sm" ||
      childState === ""
    ) {
      setArticleShares(childState)
    } else if (childState === "pa" || childState === "pd") {
      setPostedtime(childState)
    }
  }

  return (
    <section className="container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <FeedHeader
            onArticleSharesChange={changeArticleSharesHandler}
            articleShares={articleShares}
            sortPostedtime={sortPostedtime}
          />
          <FeedList feedData={feedData} onPost />
        </>
      )}
    </section>
  )
}

export default FeedPage
