import ConfigHeader from "./ConfigHeader"
import ConfigList from "./ConfigList"
import { useEffect, useState } from "react"
import { acb } from "../../../lib/axios"
import usePage from "../../../context/usePage"
import LoadingSpinner from "../../utilities/LoadingSpinner"

const ConfigPage = () => {
  const [configAutoFeedData, setConfigAutoFeedData] = useState([])
  const [configFeedData, setConfigFeedData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken
  const { selectedPage } = usePage()

  const pageConfigIndex = configAutoFeedData.findIndex(
    (entry) => entry.target === selectedPage.id
  )
  const pageConfigData =
    pageConfigIndex !== -1 ? configAutoFeedData[pageConfigIndex] : null
  const pageConfigSources = []

  if (pageConfigData && configFeedData) {
    pageConfigData.sources.forEach((source, index) => {
      pageConfigSources[index] = configFeedData.find(
        (entry) => entry.name === source.name
      )
    })
  }

  const mergedConfigData = { ...pageConfigData }

  if (mergedConfigData.sources) {
    mergedConfigData.sources = mergedConfigData.sources.map(
      (source, index) => ({
        ...source,
        settings: pageConfigSources[index],
      })
    )
  }

  useEffect(() => {
    const fetchAutoFeedConfigData = async () => {
      try {
        const response = await acb.get("autofeed/config", {
          headers: {
            "Session-Token": token,
          },
        })
        setConfigAutoFeedData(response.data.data.auto_feed_config.channels)
      } catch (error) {
        console.error(error)
      }
    }

    const fetchFeedConfigData = async () => {
      try {
        const response = await acb.get("feed/config", {
          headers: {
            "Session-Token": token,
          },
        })
        setConfigFeedData(response.data.data.feed_config.sources)
      } catch (error) {
        console.error(error)
      }
    }

    const fetchAll = async () => {
      setIsLoading(true)
      await fetchAutoFeedConfigData()
      await fetchFeedConfigData()
      setIsLoading(false)
    }

    fetchAll()
  }, [selectedPage, token])

  const saveAutoFeed = async (change) => {
    const newEntries = [...configAutoFeedData]

    if (pageConfigIndex === -1) {
      newEntries.push({
        sources: [],
        enabled: false,
        target: selectedPage.id,
        shurlEnabled: true,
        utmEnabled: true,
        ...change,
      })
    } else {
      newEntries[pageConfigIndex] = {
        ...pageConfigData,
        ...change,
      }
    }

    try {
      const response = await acb.post(
        "autofeed/config",
        {
          auto_feed_config: {
            channels: newEntries,
          },
        },
        {
          headers: {
            "Session-Token": token,
          },
        }
      )
      if (response.status === 200) {
        setConfigAutoFeedData(newEntries)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const saveFeed = async (index, change) => {
    const newEntries = [...configFeedData]

    if (index === -1) {
      newEntries.push({
        backFetch: null,
        enabled: true,
        name: "",
        url: "",
        ...change,
      })
    } else {
      if (change === null) {
        delete newEntries[index]
      } else {
        newEntries[index] = {
          ...pageConfigSources[index],
          ...change,
        }
      }
    }

    try {
      const response = await acb.post(
        "feed/config",
        {
          feed_config: {
            sources: newEntries,
          },
        },
        {
          headers: {
            "Session-Token": token,
          },
        }
      )
      if (response.status === 200) {
        setConfigFeedData(newEntries)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const toggleEnableHandler = (status) => {
    saveAutoFeed({ enabled: status })
  }

  const toggleFeedStatusHandler = (index, status) => {
    saveFeed(index, { enabled: status })
  }

  const createCommentHandler = (newComment) => {
    const newSource = {
      name: newComment.name,
      back_d: null,
    }
    saveAutoFeed({ sources: [...(pageConfigData?.sources || []), newSource] })
    saveFeed(-1, {
      name: newComment.name,
      enabled: newComment.status,
      url: newComment.url,
    })
  }

  const deleteCommentHandler = (index) => {
    saveAutoFeed({
      sources: pageConfigData.sources.filter((_, i) => i !== index),
    })
    saveFeed(index, null)
  }

  return (
    <section className="container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <ConfigHeader
            configData={pageConfigData}
            onEnableToggle={toggleEnableHandler}
          />

          <ConfigList
            feedData={mergedConfigData?.sources || []}
            onFeedStatusToggle={toggleFeedStatusHandler}
            onFeedCreate={createCommentHandler}
            onFeedDelete={deleteCommentHandler}
          />
        </>
      )}
    </section>
  )
}

export default ConfigPage
