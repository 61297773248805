import { MdDeleteForever } from "react-icons/md"
import { confirmAlert } from "react-confirm-alert" // Import
import styles from "./ConfigPost.module.scss"
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import usePage from "../../../context/usePage"

const ConfigPost = ({ config, onParentPageDelete }) => {
  const { allPages } = usePage()
  const matchedPage = allPages.find(
    (page) => page.id === Number(config.parent_page_id)
  )

  const removeCommentHandler = () => {
    confirmAlert({
      title: "Are you sure you want to delete this AWESOME parent?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            onParentPageDelete(config.id)
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  return (
    <tr className="table_content">
      <td>
        <div className={styles.child_page_info}>
          <img src={matchedPage?.logo} alt={matchedPage?.name} />
          <p>{matchedPage?.name || "[?]"}</p>
        </div>
      </td>
      <td>
        <p>{config.daily_posts}</p>
      </td>
      <td>
        <p>{config.delay_minutes}</p>
      </td>
      <td>
        <button
          className="primary_outline_btn delete"
          onClick={removeCommentHandler}
        >
          <span>Remove</span>
          <MdDeleteForever size={20} />
        </button>
      </td>
    </tr>
  )
}

export default ConfigPost
