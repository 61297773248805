import styles from "./ShareBox.module.scss"
import { BiLinkExternal } from "react-icons/bi"
import Dropdown from "./Dropdown"
import usePage from "../../context/usePage"
import { useState } from "react"
import { useEffect } from "react"
import { acb } from "../../lib/axios"

const ShareBox = ({
  onCloseShareBox,
  currentPost,
  currentMediaUrl,
  postId,
}) => {
  // console.log("current post", currentPost)
  const currentLink = currentPost.post || currentPost.url
  const { selectedPage, allPages } = usePage()
  const defaultShareInfo = {
    page: selectedPage,
    title: currentPost.title,
    image: currentMediaUrl,
    image_id: currentPost.img_id,
    content: "",
    link: currentLink || "",
    shareDate: "",
    shareTime: "",
    shareNow: true,
    postId: postId,
  }
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken

  const [shareInfo, setShareInfo] = useState(defaultShareInfo)
  const [isPublishing, setIsPublishing] = useState(false)

  const allPageList = (allPages) => {
    return allPages.map((page) => {
      return { value: page.id, label: page.name }
    })
  }

  const changePageHandler = (selectedPage) => {
    const page = allPages.find((page) => page.id === selectedPage)
    setShareInfo((prev) => ({ ...prev, page }))
  }

  const shareFeedPostHandler = async (e) => {
    e.preventDefault()
    setIsPublishing(true)

    let response = null

    if (shareInfo.shareNow) {
      response = await acb.post(
        "fb/post",
        {
          page_id: shareInfo.page.id,
          caption: shareInfo.content,
          link: shareInfo.link,
          img_id: shareInfo.image_id,
          page_id_img_src: Number(currentPost.pid.split("_")[0]), // TODO ask to get the page of the image from the API, this is probably not reliable
        },
        {
          headers: {
            "Session-Token": token,
          },
        }
      )
    } else {
      if (!shareInfo.shareDate || !shareInfo.shareTime) {
        return
      }

      // TODO find a way to publish images as well, currently only text is supported
      response = await acb.post(
        "calendar/event/add",
        {
          st: token,
          dt: `${shareInfo.shareDate} ${shareInfo.shareTime}:00`,
          name: "fb_page_post",
          params: {
            page_name: shareInfo.page.name,
            content: shareInfo.content,
            post_url: shareInfo.link,
          },
        },
        {
          headers: {
            "Session-Token": token,
          },
        }
      )
    }

    setIsPublishing(false)

    if (response !== null && response.status === 200) {
      onCloseShareBox(defaultShareInfo)
    }
  }
  useEffect(() => {
    const htmlTag = document.getElementsByTagName("html")[0]
    htmlTag.style.overflow = "hidden"

    return () => {
      htmlTag.style.overflow = "auto"
    }
  }, [])
  // console.log("share info", shareInfo)

  return (
    <div className={`overlay ${styles.modal}`}>
      <div className={styles.wrapper}>
        <form className={styles.share_form} onSubmit={shareFeedPostHandler}>
          <div className={styles.content}>
            <div className={styles.page_title}>
              <Dropdown
                options={allPageList(allPages)}
                value={shareInfo.page.id}
                onChangeOpt={changePageHandler}
              />
            </div>
            <main>
              <div>
                {shareInfo.content && (
                  <textarea
                    name=""
                    value={shareInfo.content}
                    onChange={(e) =>
                      setShareInfo((pre) => ({
                        ...pre,
                        content: e.target.value,
                      }))
                    }
                  ></textarea>
                )}
                {shareInfo.image && (
                  <div className={styles.img_wrapper}>
                    <img src={shareInfo.image} alt="poster-img" />
                  </div>
                )}
                <div className={styles.page_info}>
                  <span>{selectedPage.name}</span>
                  <h2>{shareInfo.title}</h2>
                </div>
                <div className={styles.input_area}>
                  <input
                    type="url"
                    value={shareInfo.link}
                    readOnly
                    onChange={(e) =>
                      setShareInfo((pre) => ({ ...pre, link: e.target.value }))
                    }
                    placeholder="Input your link to share"
                  />
                  {shareInfo.link && (
                    <a href={shareInfo.link} target="_blank" rel="noreferrer">
                      <BiLinkExternal size={17} />
                    </a>
                  )}
                </div>
              </div>
            </main>
            <aside>
              <span>Share Time</span>
              <div className={styles.sidebarGroup}>
                <label htmlFor="now">
                  <input
                    type="radio"
                    name="time"
                    id="now"
                    className={styles.input_circle}
                    defaultChecked={shareInfo.shareNow}
                    onClick={(e) =>
                      setShareInfo((pre) => ({ ...pre, shareNow: true }))
                    }
                  />
                  <span>Now</span>{" "}
                  <div className={styles.details}>
                    This takes a few seconds longer as we have to wait for
                    confirmation from Facebook that the share has been made.
                  </div>
                </label>
              </div>
              <div className={styles.sidebarGroup}>
                <label htmlFor="specifictime">
                  <input
                    type="radio"
                    name="time"
                    id="specifictime"
                    className={styles.input_circle}
                    onChange={(e) =>
                      setShareInfo((pre) => ({ ...pre, shareNow: false }))
                    }
                  />
                  <span>Specific Time</span>
                  <div className={styles.details}>
                    <input
                      type="date"
                      className="primary_outline_btn"
                      value={shareInfo.shareDate}
                      onChange={(e) =>
                        setShareInfo((pre) => ({
                          ...pre,
                          shareDate: e.target.value,
                        }))
                      }
                    />
                    <input
                      type="time"
                      className="primary_outline_btn"
                      value={shareInfo.shareTime}
                      onChange={(e) =>
                        setShareInfo((pre) => ({
                          ...pre,
                          shareTime: e.target.value,
                        }))
                      }
                    />
                  </div>
                </label>
              </div>
            </aside>
          </div>
          <footer>
            <button
              type="button"
              className="primary_outline_btn"
              onClick={onCloseShareBox}
              disabled={isPublishing}
            >
              Cancel
            </button>
            <button
              className="primary_btn"
              type="submit"
              disabled={isPublishing}
            >
              Condividi
            </button>
          </footer>
        </form>
      </div>
    </div>
  )
}

export default ShareBox
