import styles from "./QueuePost.module.scss"
import { useState } from "react"
import { LuClipboardCheck, LuClipboardX } from "react-icons/lu"
import {
  HiMiniMagnifyingGlassPlus,
  HiMiniMagnifyingGlassMinus,
} from "react-icons/hi2"
import QueueChildPost from "./QueueChildPost"
import { TbReplaceFilled } from "react-icons/tb"
import noimage from "../../../images/noimage.jpg"
import { api } from "../../../lib/axios"

const QueuePost = ({ post, onPostAction }) => {
  const [displayChildTable, setDisplayChildTable] = useState(false)
  const [selectedImgId, setSelectedImgId] = useState(post.choosen_image)
  const [displayImgGallery, setDisplayImgGallery] = useState(false)
  const [imagesList, setImagesList] = useState(post.images)
  const [isImageUploading, setIsImageUploading] = useState(false)
  const choosenImage = selectedImgId
    ? imagesList.find((img) => img.id === selectedImgId)
    : null

  const defaultImageUrl = choosenImage ? choosenImage.content : noimage
  const [postContent, setPostContent] = useState(post.content)
  const [isShowMore, setShowMore] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const isMessageTooLong = postContent.length > 150
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken

  const handleImageUpload = async (e) => {
    setIsImageUploading(true)

    const formData = new FormData()

    formData.append("post", post.id)
    formData.append("content", e.target.files[0])

    try {
      const response = await api.post("/redrawn-image/", formData, {
        headers: {
          "Session-Token": token,
        },
      })
      if (response.status === 201) {
        setImagesList((prev) => [
          ...prev,
          {
            id: response.data.id,
            preview: response.data.content,
          },
        ])
      }
    } catch (error) {
      console.log(error)
    } finally {
      e.target.value = null
      setIsImageUploading(false)
    }
  }

  return (
    <>
      <tr className={`table_content ${styles.wrapper}`}>
        <td>
          <div className={styles.post_content}>
            <button
              htmlFor="expand"
              className={styles.expand_btn}
              onClick={() => setDisplayChildTable(!displayChildTable)}
            >
              {displayChildTable ? (
                <HiMiniMagnifyingGlassMinus
                  size={20}
                  className={styles.minus}
                />
              ) : (
                <HiMiniMagnifyingGlassPlus size={20} className={styles.plus} />
              )}
            </button>
            <button
              className={styles.img_wrapper}
              onClick={() => setDisplayImgGallery(!displayImgGallery)}
            >
              <img src={defaultImageUrl} alt="post-img" />
              <div className={styles.change_icon}>
                <TbReplaceFilled size={30} />
              </div>
            </button>
            <div className={styles.main_content}>
              {/* <textarea
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
              /> */}
              {/* {isShowMore || !isMessageTooLong
                  ? postContent
                  : postContent.slice(0, 150) + "…"}{" "}
                {isMessageTooLong && (
                  <button
                    className="show_more"
                    onClick={() => setShowMore(!isShowMore)}
                  >
                    {isShowMore ? "Show less" : "Show more"}
                  </button>
                )} */}

              {/* <p
                contentEditable={isEditing}
                suppressContentEditableWarning
                onInput={handleInputContent}
                onClick={handleEditClick}
                onBlur={handleBlur}
              >
                {isShowMore || !isMessageTooLong
                  ? postContent
                  : postContent.slice(0, 150) + "…"}
              </p> */}

              {isEditing ? (
                <textarea
                  value={postContent}
                  rows={20}
                  onChange={(e) => setPostContent(e.target.value)}
                  onBlur={() => setIsEditing(false)}
                  autoFocus
                  style={{ fontSize: 16 }}
                />
              ) : (
                <>
                  <div onClick={() => setIsEditing(true)}>
                    {(isShowMore || !isMessageTooLong
                      ? postContent
                      : postContent.slice(0, 150) + "… "
                    )
                      .split("\n")
                      .filter((str) => str)
                      .map((str, index) => (
                        <p key={index}>{str}</p>
                      ))}
                  </div>
                  {isMessageTooLong && (
                    <button
                      className="show_more"
                      onClick={() => setShowMore(!isShowMore)}
                    >
                      {isShowMore ? "Show less" : "Show more"}
                    </button>
                  )}
                </>
              )}

              {/* <p onClick={handleEditClick}>
                {isShowMore || !isMessageTooLong
                  ? postContent
                  : postContent.slice(0, 150) + "…"}
              </p>
              {isMessageTooLong && (
                <button
                  className="show_more"
                  onClick={() => setShowMore(!isShowMore)}
                >
                  {isShowMore ? "Show less" : "Show more"}
                </button>
              )} */}
            </div>
          </div>
        </td>
        <td>
          <div className={styles.action_btns}>
            <button
              value="approved"
              className={`primary_btn ${styles.approve}`}
              onClick={(e) =>
                onPostAction(e.target.value, postContent, selectedImgId)
              }
            >
              <LuClipboardCheck size={22} />
            </button>

            <button
              value="rejected"
              className={`primary_btn ${styles.disapprove}`}
              onClick={(e) =>
                onPostAction(e.target.value, postContent, selectedImgId)
              }
            >
              <LuClipboardX size={22} />
            </button>
          </div>
        </td>
      </tr>
      <tr className={`${styles.gallery_wrapper}`}>
        <td colSpan={2}>
          <div
            className={`${styles.replace_images_wrapper} ${
              displayImgGallery ? styles.display : styles.hide
            }`}
          >
            <h2>
              <TbReplaceFilled size={20} />
              <span>Alternative:</span>
            </h2>

            <div className={`${styles.replace_images}`}>
              {imagesList.map((img) => (
                <button key={img.id} onClick={() => setSelectedImgId(img.id)}>
                  <img
                    src={img.preview}
                    alt={`replace_image_${img.id}`}
                    className={`${selectedImgId === img.id && styles.selected}`}
                    loading="lazy"
                  />
                </button>
              ))}
              <label
                key="add"
                style={{
                  width: 110,
                  height: 110,
                  border: "2px dashed #999",
                  fontSize: isImageUploading ? 12 : 24,
                  color: "#999",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isImageUploading ? "Uploading…" : "+"}
                <input
                  type="file"
                  onChange={handleImageUpload}
                  accept="image/*"
                  disabled={isImageUploading}
                  style={{ display: "none" }}
                />
              </label>
            </div>
          </div>
        </td>
      </tr>
      <QueueChildPost
        displayChildTable={displayChildTable}
        originalPostContent={post.original_content}
        originalPostLink={`https://www.facebook.com/${post.reference_id}`}
      />
    </>
  )
}

export default QueuePost
