import styles from "./AnalyticsPost.module.scss"
import noimage from "../../../images/noimage.jpg"
import { useEffect, useState } from "react"
import ShareBox from "../../utilities/ShareBox"
import { useInView } from "react-intersection-observer"
import usePage from "../../../context/usePage"
import { acb } from "../../../lib/axios"
import { useRef } from "react"
import moment from "moment"
import { IoIosLink } from "react-icons/io"

function getPostUrl(analyticsData) {
  const shareAttachments = analyticsData.attachments.filter(
    (attachment) => attachment.type === "share"
  )

  if (shareAttachments.length > 0) {
    const facebookUrl = shareAttachments[0].payload
    const postUrl = facebookUrl.match(/\?u=(.*?)&/)

    if (postUrl) {
      return decodeURIComponent(postUrl[1])
    }
  }

  return null
}

const AnalyticsPost = ({ analyticsData, orderedColumn }) => {
  const [shareBoxShow, setShareBoxShow] = useState(false)
  const [imageUrl, setImageUrl] = useState("")
  const imageUrlRef = useRef()
  const [isImageRequested, setIsImageRequested] = useState(false)
  const token = JSON.parse(localStorage.getItem("loggedInfo")).sessionToken
  const { selectedPage } = usePage()
  const { ref, inView } = useInView({ rootMargin: "200px" })
  const [isShowMore, setShowMore] = useState(false)

  const isMessageTooLong = analyticsData.message.length > 150

  const openShareBoxHandler = () => {
    setShareBoxShow(true)
  }
  const closeShareBoxHandler = () => {
    setShareBoxShow(false)
  }

  const addCommasToNumber = (number) => {
    let numStr = number.toString()
    numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    return numStr
  }

  const covertToCommonPercentage = (number) => {
    const percentageValue = (number * 100).toFixed(1)
    const changeComma = percentageValue.replace(".", ",") + "%"

    return changeComma
  }

  const mustTriggerFetchImage = isImageRequested || inView

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await acb.get("media/img", {
          responseType: "blob",
          params: {
            page_id: selectedPage.id,
            img_id: analyticsData.img_id,
          },
          headers: {
            "Session-Token": token,
          },
        })
        imageUrlRef.current = URL.createObjectURL(response.data)
        setImageUrl(imageUrlRef.current)
      } catch (error) {
        console.log(error)
      }
    }

    if (inView) {
      fetchImage()
      setIsImageRequested(true)
    }

    return () => {
      if (imageUrlRef.current) {
        revokeImageUrl(imageUrlRef.current)
      }
    }
  }, [
    mustTriggerFetchImage,
    analyticsData.img_id,
    inView,
    selectedPage.id,
    token,
  ])

  function revokeImageUrl(url) {
    if (url) {
      URL.revokeObjectURL(url)
    }
  }

  return (
    <tr className="table_content" ref={ref}>
      <td className={styles.post_content}>
        <div className={styles.img_wrapper}>
          <img src={imageUrl || noimage} alt="poster-img" />
        </div>
        <div className={styles.main_content}>
          <div className={styles.info}>
            <a
              href={`https://www.facebook.com/${analyticsData.pid}`}
              className={styles.time}
              target="_blank"
              rel="noreferrer"
            >
              <IoIosLink /> Shared on{" "}
              {moment(analyticsData.created).format("D MMMM YYYY, hh:mm")}
            </a>
            <p className={styles.title}>
              {isShowMore || !isMessageTooLong
                ? analyticsData.message
                : analyticsData.message.slice(0, 150) + "…"}{" "}
              {isMessageTooLong && (
                <button
                  className="show_more"
                  onClick={() => setShowMore(!isShowMore)}
                >
                  {isShowMore ? "Show less" : "Show more"}
                </button>
              )}
            </p>
          </div>
          <button className="primary_outline_btn" onClick={openShareBoxHandler}>
            Condividi
          </button>
          {shareBoxShow && (
            <ShareBox
              onCloseShareBox={closeShareBoxHandler}
              currentPost={{
                ...analyticsData,
                url: getPostUrl(analyticsData),
              }}
              currentMediaUrl={imageUrl}
              postId={analyticsData.pid}
            />
          )}
        </div>
      </td>
      <td
        className={`analysis ${
          orderedColumn === "impressions" ? styles.highlighted : ""
        }`}
      >
        {addCommasToNumber(analyticsData.impressions)}
      </td>
      <td
        className={`analysis ${
          orderedColumn === "clicks" ? styles.highlighted : ""
        }`}
      >
        {analyticsData.clicks}
      </td>
      <td
        className={`analysis ${
          orderedColumn === "ctr" ? styles.highlighted : ""
        }`}
      >
        {covertToCommonPercentage(analyticsData.ctr)}
      </td>
      <td
        className={`analysis ${
          orderedColumn === "engagement" ? styles.highlighted : ""
        }`}
      >
        {addCommasToNumber(analyticsData.reactions)}
      </td>
    </tr>
  )
}

export default AnalyticsPost
